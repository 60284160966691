import React from 'react'
import Gig from './Gig'
import './Gigs.css'
import gigs from './data/gigs.json'

const now = new Date()

const threshold = 1000 * 60 * 60 * 12

const pastGigs = gigs.filter(gig => {
  return new Date(gig.date) < now - threshold
})

const byDate = (a, b) => new Date(a.date) - new Date(b.date)

const futureGigs = gigs.filter(gig => {
  return new Date(gig.date) >= now - threshold
}).sort(byDate)

const Gigs = () => {
  return (
    <section id='gigs' className='gigs'>
      <div className='gigs-content'>
        <h1>Gigs</h1>
        <h2>Upcoming</h2>
        <ul>
          {
            futureGigs.map(gig => (
              <Gig
                {...gig}
                key={gig.date}
                isTBD={!gig.venue}
              />
            ))
          }
          {
            futureGigs.length < 1
            && 'Nothing planned yet'
          }
        </ul>
        <h2>Past gigs</h2>
        <ul>
          {
            pastGigs.map(gig => (
              <Gig
                {...gig}
                key={gig.date}
              />
            ))
          }
        </ul>
      </div>
    </section>
  )
}

export default Gigs
