import React from 'react'
import logo from "./images/logo.webp";
import members from './images/musicians2024.webp'
import "./Intro.css"

const Intro = () => {
  return (
    <div
      className='intro'
    >
      <div className="intro-content">
        <div className='text'>
          <img
            height='300'
            src={logo}
            className="logo"
            alt="My freezer hero"
          />
          <div className='special'>Rock & Punk cover band!</div>
        </div>
        <div className='members'>
          <img
            height='500'
            src={members}
            className="members-image"
            alt="My freezer hero"
          />
        </div>
      </div>
    </div>
  )
}

export default Intro