import React from 'react'
import DateBox from './DateBox'
import './Gig.scss'
import pin from './images/pin.svg'
import defaultFlyer from './images/flyer-placeholder.jpg'

const Gig = ({
  isTBD,
  date,
  venue,
  location,
  moreInfo,
  flyer
}) => {

  const flyerSrc = flyer ? require(`./images/flyers/${flyer}`) : defaultFlyer

  const flyerImage = <img
    width={300}
    className='flyer'
    src={flyerSrc}
    alt=''
  />

  return (
    <li className='gig'>
      <div className="flyer-container">
        {
          !!flyer
          ? <a href={flyerSrc}>
            {flyerImage}
          </a>
          : flyerImage
        }
      </div>
      <div className="info">
        <DateBox
          isTBD={isTBD}
          date={new Date(date)}
        />
        <div className="details">
          <div className="venue">
            {isTBD ? 'TBD' : venue}
          </div>
          <div className="location">
            {
              isTBD
                ? ''
                : <a className='location-link' href={`https://www.google.com/maps/search/?api=1&query=${location}`} target='_blank' rel='noreferrer'>
                    <span className='pin-icon'>
                      <img src={pin} alt='' height={20}/>
                    </span>
                    {location}
                  </a>
            }
          </div>
          <div className="more-info">
            {
              moreInfo
                ? Array.isArray(moreInfo)
                  ? moreInfo.map((link, index) => {
                    return <a href={link} className='more-info__link' target='_blank' rel='noreferrer'>Info {index + 1}</a>
                  })
                  : <a href={moreInfo} className='more-info__link' target='_blank' rel='noreferrer'>More info</a>
                : null
            }
          </div>
        </div>
      </div>
    </li>
  )
}

export default Gig