import { format } from 'date-fns'
import React from 'react'

import './DateBox.scss'

const DateBox = ({
  isTBD,
  date
}) => {
  const dayOfWeek = format(date, 'EEEE')
  const day = format(date, 'dd MMM')
  const year = format(date, 'yyyy')
  const time = isTBD ? '' : format(date, 'HH:mm')
  return (
    <div className='date'>
      <div className="date__day-of-week">{dayOfWeek}</div>
      <div className="date__day">{day} {year}</div>
      {
        !isTBD && <div className="date__time">@ {time} </div>
      }
    </div>
    
  )
}

export default DateBox